<template>
  <v-overlay :value="showWait" color="white">
    <v-progress-circular :size="100" :width="8" color="teal" indeterminate
      >Loading...</v-progress-circular
    >
  </v-overlay>
</template>

<script>
export default {
  props: {
    showWait: Boolean,
    str: String,
  },
  created() {},
};
</script>
<style>
:root {
  --spinner-body-bg: #d1d8e0;
  --spinner-animation-speed: 1s;
  --spinner-color: #8854d0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
</style>
